.App-carousel-slider {
  max-width: 1175px;
  margin: auto 10%;

  display: flex;
  margin: auto;
  justify-content: center;
  flex-flow: row;
  align-content: space-around;
  text-align: center;
}

.App-carousel-slider-button {
  position: relative;
  border: none;
  overflow: hidden;
  outline: 0;
  background: transparent;
  cursor: pointer;
}

.App-carousel-slider-button img {
  width: 40px;
  height: 40px;
}

.App-carousel-slider-dots {
  position: relative;
  margin: 40px auto auto;
}

:global(.BrainhubCarouselItem) {
  padding: 5px 0;
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__dot) {
  opacity: 1;
  padding: 5px;
}
:global(.BrainhubCarousel__dots .BrainhubCarousel__dot:before) {
  background: #4b56cd;
  width: 8px;
  height: 8px;
}
:global(.BrainhubCarousel__dots
    .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before) {
  background: #e0e9fd;
  width: 8px;
  height: 8px;
}

@media screen and (max-width: 1500px) {
  .App-carousel-slider {
    width: 90%;
  }
}

@media screen and (max-width: 684px) {
  .App-carousel-navbar {
    display: none;
  }
}
