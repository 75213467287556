.App-header-nav-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  margin-top: 0;
  top: 72px;
  flex-direction: column;
  background: black;
  right: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 5;
  padding-left: 0px;
  visibility: unset;
}

.App-header-nav-social {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  bottom: env(safe-area-inset-bottom, 0px);
  z-index: 10;
}

.App-header-nav-social-title {
  color: #fafafa;
  font-size: 20px;
  margin-left: 2rem;
  font-family: "Maax-Medium";
}

.App-header-nav-social-bar {
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
  justify-content: space-between;
  min-width: 100px;
}
