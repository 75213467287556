.App-welcome {
  width: 100%;
  height: 73vh;
  background-image: url("../Assets/Backgrounds/main_kv.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 40%;
}

.App-welcome-title {
  color: #de65cc;
  position: relative;
  margin: auto;
  top: 20%;
  font-weight: 900;
  font-size: 42px;
  letter-spacing: 2px;
  font-family: "Maax-Black";
}

.App-welcome-mightymeta {
  color: white;
  position: relative;
  margin: auto;
  top: 17%;
  font-weight: 900;
  font-size: 120px;
  letter-spacing: 10px;
  font-family: "Maax-Black";
}

.App-welcome-intro {
  color: #dddad7;
  position: relative;
  margin: auto;
  top: 19%;
  max-width: 535px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  font-family: "Maax-Medium";
}

.App-welcome-arrow {
  width: 40px;
  height: 40px;
  position: relative;
  bottom: -60%;
  margin: auto auto 0 auto;
  cursor: pointer;
}

.App-welcome-arrow-img {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1200px) {
  .App-welcome {
    height: 90vh;
    background-position: 50%;
  }

  .App-welcome-title {
    font-size: 24px;
  }

  .App-welcome-mightymeta {
    font-size: 74px;
    letter-spacing: 5px;
    line-height: 74px;
    top: 22%;
  }

  .App-welcome-intro {
    margin: 20px auto;
    padding: 0 25px;
    top: 22.5%;
  }
}
