.container {
  @apply flex flex-row justify-between bg-[#13161A] w-full p-[7%] pt-[6%] pb-[5.5%] text-left;
}

.email-form-container {
  @apply flex flex-col flex-1 ml-[10%];
}

.email-form {
  @apply mb-[25px] ml-[-4px] w-[585px] max-w-full;
}

.email-text {
  @apply text-[#DCDFE6] text-[14px] font-[Maax-Regular];
}

@media screen and (max-width: 1200px) {
  .container {
    @apply flex-col p-[5%] pt-[60px] pb-[40px];
  }

  .email-form-container {
    @apply items-start w-[585px] max-w-full ml-0;
  }

  .email-form {
    @apply mb-0 pb-[10px];
  }

  .email-text {
    @apply mb-[55px];
  }
}
/* w-[585px] max-w-full */
