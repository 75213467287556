.Card {
  background: linear-gradient(#020810 20%, #263d95, #281a4b);
  width: 334px;
  height: 515px;

  -webkit-box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.05);
  -moz-box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.05);
}

.Card a {
  color: inherit;
  text-decoration: none;
}

.Card-image {
  margin: 10px;
  width: 316px;
  height: 334px;
  object-fit: cover;
}

.Card-title {
  margin: 25px 0 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  font-family: "Maax-Bold";
}

.Card-description {
  margin: 0;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 28px;
  margin-bottom: 0;
  color: #f6f0fa;
  font-family: "Maax-Medium";
}

.Card-button {
  display: block;
  position: relative;
  margin: 25px auto 0;
  border: 2px solid #3f7bf6;
  border-radius: 120px;
  width: 75%;
  height: 32px;
  background: #3f7bf6;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #f6f0fa;
}
.Card-button-available {
  border: 2px solid #ffffff;
  width: 50%;
  background: #ffffff;
  font-size: 12px;
  color: #050d24;
}

.Card-button-unavailable {
  margin: 25px 5px 0 auto;
  border: 2px solid #484d70;
  width: 42%;
  background: #484d70;
  font-size: 12px;
  color: #f6f0fa;
}

.Card-button-coming-soon {
  border: 2px solid #484d70;
  width: 50%;
  background: #484d70;
  font-size: 12px;
  color: #f6f0fa;
}

.Card-button-view {
  border: 2px solid #3f7bf6;
  width: 42%;
  font-size: 12px;
  color: #f6f0fa !important;
  background: transparent;
}

.Card-button {
  @apply flex items-center justify-center text-center font-[Maax-Medium] pt-[1px];
}

@media screen and (max-width: 600px) {
  .Card {
    height: auto;
    padding-bottom: 20px;
  }

  .Card-image {
    max-width: 310px;
    object-fit: cover;
  }
}
