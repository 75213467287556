.App-header {
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
  background-color: #3077ff;
}

.App-header-logo-nav {
  position: absolute;
  left: 15px;
}

.connect-wallet-btn {
  position: absolute;
  right: 30px;
  @apply flex items-center justify-center bg-[#000000] text-white text-[13px] font-bold text-center pl-[17px] pr-[17px] pt-[10px] pb-[7px] rounded-[18px] font-[Maax-Bold];
}

.App-mightymeta-logo {
  height: 50px;
  width: auto;
  object-fit: contain;
  cursor: pointer;
  transition: 0.5s;
}

.App-header-nav-options {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  gap: 50px;
  list-style-type: none;
  font-family: "Maax-Bold";
}

.App-header-nav-option i {
  display: none;
}

.App-header-nav-option a {
  font-weight: bold;
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.App-header-nav-option a.active {
  color: #3077ff;
}

.App-header-nav-option :hover {
  color: #27509c;
}

.App-header-nav-social {
  display: none;
}

.App-header-nav-social-container {
  border: 1px solid #646a84;
  width: 196px;
  height: 59px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.App-header-social {
  width: 90px;
  height: 48px;
  /*border: 2px solid #3060ff;
  background: linear-gradient(#183d84, rgba(24, 61, 132, 0.4));*/
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin: 10px 20px;
  margin-left: auto;
}

.App-header-hamburger {
  display: none;
}

.App-header-nav-sub-option {
  opacity: 0;
  display: none;
  visibility: hidden;
}

.App-mightymeta-white-logo {
  height: 36px;
  width: auto;
}

@media screen and (max-width: 1200px) {
  .App-header {
    display: none;
  }

  .App-header-logo-nav {
    display: none;
  }

  .App-mightymeta-logo {
    height: 56px;
    width: auto;
    transition: 0.5s;
  }

  .App-header-nav-options.active {
    background: black;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 5;
    align-content: center;
  }

  .App-header-nav-options {
    margin-left: 20px;
  }

  .App-header-social {
    display: none;
  }

  .App-header-nav-social {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    position: fixed;
    width: 100%;
    bottom: 0px;
    bottom: env(safe-area-inset-bottom, 0px);
    background: #20293b;
    z-index: 10;
  }

  .App-header-nav-social-bar {
    margin-left: auto;
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
    min-width: 100px;
  }

  .App-header-nav-social-icon {
    background: black;
    border: 1px solid #9a9a9a;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .App-header-nav-sub-option {
    width: 100%;
    height: 70vh;
    /* display: flex; */
    justify-content: flex-start;
    position: absolute;
    /* top: 56px; */
    z-index: 10;
    background: black;
    right: -100%;
    display: unset;
    opacity: 0;
    transition: all 0.5s ease;
    visibility: hidden;
  }

  .App-header-nav-sub-option.active {
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    visibility: unset;
  }

  .App-header-nav-sub-list {
    padding-left: 0;
  }

  .App-header-nav-sub-list-back {
    color: #8b8b8b;
    margin-left: 10px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .App-header-nav-sub-list-back span {
    -webkit-text-size-adjust: none;
  }

  .App-header-hav-sub-list-back-text {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 2px;
  }

  .App-header-nav-sub-list-option {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 40px;
    padding: 12px 0px;
    border-bottom: 1px solid #2f2f2f;
    z-index: 6;
    padding-left: 32px;
  }

  .App-header-nav-sub-list-option:first-child {
    border: none;
    padding: 0;
    padding-left: 32px;
  }

  .App-header-nav-sub-list-option:first-child a {
    color: #8b8b8b;
    font-size: 500;
    text-transform: uppercase;
  }

  .App-header-nav-sub-list-option:nth-child(2) a {
    color: #3077ff;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
  }

  .App-header-nav-sub-list-option a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
  }

  .App-header-mobile-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 10px 20px;
    margin-left: auto;
  }

  .App-header-hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 20px 0 0px 10px;
    height: 40px;
  }

  /* CSS magick for hamburger menu icon */

  .App-header-hamburger input {
    display: block;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: -20px;
    margin: 0px 20px;
    width: 56px;
    height: 56px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;

    -webkit-touch-callout: none;
  }

  .App-header-hamburger span {
    display: block;
    width: 18px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 2px;
    z-index: 1;

    transform-origin: -4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .hamburger-black span {
    background: #000000;
  }

  .App-header-hamburger span:first-child {
    transform-origin: 0% 0%;
  }

  .App-header-hamburger span:nth-last-child(1) {
    transform-origin: 0% 100%;
  }

  .connect-wallet-btn {
    display: none;
  }
}
