@tailwind base;
@tailwind components;
@tailwind utilities;

/* Maxx Fonts */

@font-face {
  font-family: "Maax-Medium";
  src: url("./Fonts/Maxx/Maax-Medium.eot");
  src: local("Maax Medium"), local("Maax-Medium"),
    url("./Fonts/Maxx/Maax-Medium.woff") format("woff"),
    url("./Fonts/Maxx/Maax-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Maax";
  src: url("./Fonts/Maxx/Maax-Italic.eot");
  src: local("Maax Italic"), local("Maax-Italic"),
    url("./Fonts/Maxx/Maax-Italic.woff") format("woff"),
    url("./Fonts/Maxx/Maax-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Maax";
  src: url("./Fonts/Maxx/Maax-BoldItalic.eot");
  src: local("Maax BoldItalic"), local("Maax-BoldItalic"),
    url("./Fonts/Maxx/Maax-BoldItalic.woff") format("woff"),
    url("./Fonts/Maxx/Maax-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Maax-Black";
  src: url("./Fonts/Maxx/Maax-Black.eot");
  src: local("Maax Black"), local("Maax-Black"),
    url("./Fonts/Maxx/Maax-Black.woff") format("woff"),
    url("./Fonts/Maxx/Maax-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Maax-Regular";
  src: url("./Fonts/Maxx/Maax-Regular.ttf");
  src: local("Maax Regular"), local("Maax-Regular"),
    url("./Fonts/Maxx/Maax-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Maax";
  src: url("./Fonts/Maxx/Maax.eot");
  src: local("Maax"), url("./Fonts/Maxx/Maax.woff") format("woff"),
    url("./Fonts/Maxx/Maax.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Maax-Bold";
  src: url("./Fonts/Maxx/Maax-Bold.eot");
  src: local("Maax Bold"), local("Maax-Bold"),
    url("./Fonts/Maxx/Maax-Bold.woff") format("woff"),
    url("./Fonts/Maxx/Maax-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Maax";
  src: url("./Fonts/Maxx/Maax-Mediumitalic.eot");
  src: local("Maax MediumItalic"), local("Maax-Mediumitalic"),
    url("./Fonts/Maxx/Maax-Mediumitalic.woff") format("woff"),
    url("./Fonts/Maxx/Maax-Mediumitalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Rubik Fonts */

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

body {
  margin: 0 auto;
  font-family: "Maax";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chevron {
  position: relative;
  height: 20px;
  line-height: 0;
  font-size: 0;
}

.chevron::before,
.chevron::after {
  position: absolute;
  display: block;
  content: "";
  border: 10px solid transparent;
}

.chevron.down::before {
  top: 0;
  border-top-color: white;
}

.chevron.down::after {
  top: -3px;
  border-top-color: black;
}

.chevron.left::before {
  right: 0;
  border: 8px solid transparent;
  border-right-color: #8b8b8b;
}

.chevron.left::after {
  right: -3px;
  border: 8px solid transparent;
  border-right-color: black;
}

.tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  @apply text-[12px] leading-[18px] p-[9px] mb-[135px] right-[5%];
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .chevron.left {
    top: -16px;
  }
}

.chevron.right::before {
  left: 0;
  border-left-color: white;
}

.chevron.right::after {
  left: -3px;
  border-left-color: black;
}

@media screen and (min-width: 1201px) {
  .hide-in-desktop {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .hide-in-mobile {
    display: none;
  }
}
