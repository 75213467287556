img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.App {
  text-align: center;
  overflow: hidden;
}

.App::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
