.h-padding {
  @apply pl-[5%] pr-[5%];
}

.container {
  @apply flex flex-row justify-between bg-[#000000] w-full text-left h-padding;
}

.container-figurine {
  @apply flex flex-row  max-w-[1366px] pb-[50px] desktop:pb-0 justify-center bg-[#000000] w-full text-left h-padding;
}

.sup {
  top: -6px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 13px solid white;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 13px solid white;
}

.thin-arrow-right {
  border: solid rgb(253, 253, 253);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.right-subcontainer {
  @apply flex flex-col flex-auto w-[300px] pt-[6.6%] ml-[4%] text-[16px] font-bold;
}

.countdown {
  @apply flex items-center justify-between bg-[#161C26] h-[72px] w-full text-[white] h-padding;
}

.nft-details-container {
  @apply flex flex-row text-[#F7F7F7] text-[18px];
}

@media screen and (max-width: 1199px) {
  .container,
  .container-figurine {
    @apply flex-col;
  }

  .right-subcontainer {
    @apply w-full ml-0;
  }

  .countdown {
    @apply flex-col h-[104px] justify-center;
  }

  .carousel-container-mobile {
    @apply flex flex-row justify-center items-center mt-[40px] mb-[10px]  overscroll-x-auto overflow-scroll;
    white-space: nowrap;

    /* Hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (max-width: 500px) {
  .container,
  .container-figurine {
    @apply p-0 mt-0 pb-[13%];
  }

  .right-subcontainer {
    @apply pl-[5%] pr-[5%] mt-0 pt-[25px];
  }

  .nft-details-container {
    @apply flex-col;
  }

  .carousel-container-mobile {
    @apply pl-[90px]  pr-[10px];
  }
}
