.h-padding {
  @apply pl-[5%] pr-[5%];
}

.container {
  @apply flex flex-row justify-between bg-[#000000] w-full pb-[10%] text-left h-padding;
}

.countdown {
  @apply flex items-center justify-between bg-[#161C26] h-[72px] w-full text-[white] h-padding;
}

.countdown-title {
  @apply text-[24px] font-[Maax-Black] mt-[2px] uppercase;
}

.countdown-time-left {
  @apply flex items-center;
}

.countdown-digits {
  @apply text-[25px] font-[Maax-Medium] mt-[5px];
}

.main-nft {
  width: 100%;
  background-image: url("../Assets/Backgrounds/bg-image-nft.jpg");
  padding: 17% 22% 22% 22%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 41%;
}

.left-subcontainer {
  @apply flex flex-col flex-auto w-[450px];
}

.right-subcontainer {
  @apply flex flex-col flex-auto w-[300px] pt-[8.2%] ml-[4%] text-[16px] font-bold;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}

.carousel-container-desktop {
  @apply flex flex-row justify-center items-center mt-[-50px];
}

.carousel-container-mobile {
  @apply hidden;
}

.nft-image {
  @apply ml-[10px] mr-[10px] cursor-pointer;
}

.gray-text {
  @apply text-[14px] text-[#999EA3] font-[Maax-Bold];
}

.nft-details-container {
  @apply flex flex-row text-[#F7F7F7] text-[18px];
}

.nft-details-bottom-container {
  @apply flex flex-row justify-between items-center mb-[25px];
}

.nft-details-text {
  @apply text-[#DCDFE6] font-normal font-[Maax-Regular] mt-[5px];
}

@media screen and (max-width: 1200px) {
  .container {
    @apply flex-col;
  }

  .left-subcontainer {
    @apply w-full;
  }

  .right-subcontainer {
    @apply w-full ml-0;
  }

  .carousel-container {
    @apply mt-[-80px];
  }

  .countdown {
    @apply flex-col h-[104px] justify-center;
  }

  .countdown-title {
    @apply text-[22px];
  }

  .countdown-digits {
    @apply text-[18px];
  }
}

@media screen and (max-width: 800px) {
  .carousel-container {
    @apply mt-[-20px];
  }
}

@media screen and (max-width: 500px) {
  .container {
    @apply p-0 mt-0 pb-[10%];
  }

  .main-nft {
    background-size: cover;
    padding: 0;
  }

  .main-nft-image {
    @apply p-[14%];
  }

  .carousel-container-desktop {
    @apply hidden;
  }

  .carousel-container-mobile {
    @apply flex flex-row justify-center items-center mt-[40px] mb-[10px] pl-[10px] overscroll-x-auto overflow-scroll;
    white-space: nowrap;
    display: inline-block; /* this should fix it */

    /* Hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .image-container {
    display: inline-block;
    cursor: pointer;
  }

  div::-webkit-scrollbar {
    /* Hide scrollbar */
    display: none; /* Chrome, Safari, Opera */
  }

  .right-subcontainer {
    @apply pl-[5%] pr-[5%];
  }
}

.sup {
  top: -6px;
}
