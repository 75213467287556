.App-releases-navbar {
  display: flex;
  position: relative;
  background: transparent;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.App-releases-nav-options {
  display: grid;
  padding: 0;
  /*grid-template-columns: repeat(5, auto);*/
  grid-template-columns: repeat(2, auto);
  grid-gap: 50px;
  gap: 50px;
  list-style-type: none;
  cursor: pointer;
}
.App-releases-nav-option a {
  font-weight: bold;
  text-decoration: none;
  color: #3077ff;
  font-family: "Rubik-Bold";
}

.App-releases-nav-option a.active {
  color: white;
}

.App-releases-nav-option :hover {
  color: gray;
}

.App-releases-heading {
  font-size: 38px;
  color: white;
  position: relative;
  margin: 9% 0 6% 0;
  font-family: "Maax-Bold";
}

.App-releases-upcoming {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 20px;
}

.App-releases-recent {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 20px;
  padding-bottom: 135px;
  max-width: 1600px;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .App-releases-navbar {
    display: none;
  }

  .App-releases-heading {
    font-size: 30px;
  }

  .App-releases-recent {
    top: 33px;
  }
}
